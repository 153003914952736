<template>
  <div>
    <v-row
      v-if="toggle"
      class="filterArea"
      no-gutters>
      <v-menu
        v-for="(filter, filterKey) in filterList"
        :key="filterKey"
        :close-on-content-click="false"
        open-on-hover
        bottom
        offset-y>
        <template v-slot:activator="{ on }">
          <v-btn class="filterList" v-on="on">
            {{filter.title}}
            <v-icon>mdi-arrow-down-thick</v-icon>
          </v-btn>
        </template>

        <OrganizzeScrollbar style="max-height: 60vh; overflow-y: auto;">
          <v-list v-if="filter.title !== 'Categorias'">
            <v-list-item v-for="(item, itemIndex) in filter.items" :key="itemIndex">
              <v-icon
                :color="((filterData[filterKey] || {}).items || [])[itemIndex] === item ? variables.colorGreenDarken : variables.colorGrayLight">
                mdi-check-bold
              </v-icon>

              <v-btn depressed @click="onSelectFilter(filterKey, itemIndex, item)">{{item.title}}  </v-btn>
            </v-list-item>
          </v-list>

          <v-list v-else >
            <v-list-item v-for="(item, itemIndex) in filter.items" :key="itemIndex">
              <v-icon :color="'#FFF'"  v-if="item.categoryId">mdi-arrow-right-bold-circle</v-icon>
              <v-icon
                :color="((filterData[filterKey] || {}).items || [])[itemIndex] === item ? variables.colorGreenDarken : variables.colorGrayLight">
                mdi-check-bold
              </v-icon>

              <v-icon :color="item.colour" v-if="!item.categoryId">mdi-arrow-right-bold-circle</v-icon>
              <v-btn :small="item.categoryId || false" depressed @click="onSelectFilter(filterKey, itemIndex, item)">{{item.title}}  </v-btn>
            </v-list-item>
          </v-list>
        </OrganizzeScrollbar>
      </v-menu>

      <v-spacer/>

      <v-checkbox
        v-model="noInportOnly"
        :label="'Incluir os importados'"
      ></v-checkbox>

      <v-spacer/>

      <v-col style="margin-right: 16px;" md="3">
        <v-menu
          v-model="showCalendar"
          transition="slide-y-transition"
          :close-on-content-click="false"
          offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datesLabel"
              label="Data da Emissão"
              append-icon="mdi-calendar"
              :hide-details="true"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined>
            </v-text-field>
          </template>

          <v-date-picker
            v-model="dates"
            :color="variables.colorPrimary"
            :close-on-content-click="false"
            no-title
            scrollable
            range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showCalendar = false">Cancel</v-btn>
            <v-btn text color="primary" @click="showCalendar = false">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-btn class="filterList" @click="enviar()">Buscar</v-btn>

      <button title="Limpar filtro">
        <v-icon @click="clearFilter()">mdi-close-thick</v-icon>
      </button>

      <div class="f">
        <div class="f-select" v-if="filterData['Records:type_='] && existeItens(filterData['Records:type_='].items)">
            <span class="f-select-title"> Filtro Tipos: </span>
            <template v-for="(rt, rk) in filterData['Records:type_='].items">
              <span @click="removeItem('Records:type_=', rk)" class="f-select-box" :key="`${rk}-rt`" v-if="rt.title">
                {{ rt.title }}
                <!-- <v-icon class="f-select-icon" :color="'red'">mdi-close</v-icon> -->
            </span>
            </template>
        </div>      

        <!-- CONTAS -->
        <div class="f-select" v-if="filterData['Records:accountId_='] && existeItens(filterData['Records:accountId_='].items)">
          <span class="f-select-title"> Filtro Contas: </span>
          <template v-for="(rt, rk) in filterData['Records:accountId_='].items">
            <span @click="removeItem('Records:accountId_=', rk)" class="f-select-box" :key="`${rk}-rt`" v-if="rt.title">
              {{ rt.title }}
              <!-- <v-icon class="f-select-box-icon" :color="'red'">mdi-close</v-icon> -->
          </span>
          </template>
        </div>

        <!-- Categorias -->
        <div class="f-select" v-if="filterData['Records:recordCategoryId_In'] && existeItens(filterData['Records:recordCategoryId_In'].items)">
          <span class="f-select-title"> Filtro Categorias: </span>
          <template v-for="(rt, rk) in filterData['Records:recordCategoryId_In'].items">
            <span @click="removeItem('Records:recordCategoryId_In', rk)" class="f-select-box" :key="`${rk}-rt`" v-if="rt.title">
              {{  filterData['Records:recordCategoryId_In'].items[rk + 1].title ? `${rt.title},` : rt.title }}
              <!-- <v-icon class="f-select-box-icon" :color="'red'">mdi-close</v-icon> -->
          </span>
          </template>
        </div>

        <!-- TAGS -->
        <div class="f-select" v-if="filterData['RecordTags:tagId_In'] && existeItens(filterData['RecordTags:tagId_In'].items)">
          <span class="f-select-title"> Filtro Contas: </span>
          <template v-for="(rt, rk) in filterData['RecordTags:tagId_In'].items">
            <span  @click="removeItem('RecordTags:tagId_In', rk)" class="f-select-box" :key="`${rk}-rt`" v-if="rt.title">
              {{ rt.title }}
              <!-- <v-icon class="f-select-box-icon" :color="'red'">mdi-close</v-icon> -->
          </span>
          </template>
        </div>
      </div>
    </v-row>
    <v-row>
    </v-row>
  </div>
</template>

<script>
import { makeSkeletonData } from '@/utils/miscellaneous'
import variables from '@/assets/styles/helpers/_variables.scss'
import Events from '@/core/service/events'
import moment from 'moment'
import { map, join, filter, compatc, size } from 'lodash'
import { isValidParam } from '@/utils/validationUtils'

export default {
  name: 'OrganizzeFilter',
  props: {
    filterList: {
      type: Object,
      required: true
    },
    submit: {
      type: Function,
      required: true
    }
  },
  directives: {},
  components: {
    OrganizzeScrollbar: () => import('@/views/components/organizze/scrollbar')
  },
  data: () => ({
    showCalendar: false,
    datesLabel: [moment().format('DD/MM/YYYY')],
    dates: [moment().format('YYYY-MM-DD')],
    sortedDates: [moment().format('YYYY-MM-DD')],
    filterDataBase: {}, /** Parâmetro para limpar o filtro. */
    filterData: {}, /** Parâmetro que idêntifica o estatus atual do filtro. */
    _addlFilter: {},
    _filter: {},
    toggle: false,
    noInportOnly: false
  }),
  created () {},
  mounted () {
    let dtFormat = 'YYYY-MM-DD HH:mm:ss'
    const self = this
    if (this.$route.params['Records:dtEmission_>=']) {
      let start = moment(this.$route.params['Records:dtEmission_>='], dtFormat).format('YYYY-MM-DD')
      let end = moment(this.$route.params['Records:dtEmission_<='], dtFormat).format('YYYY-MM-DD')

      if (start === end)
        self.dates = [start]
      else
        self.dates = [start, end]

    }

    Events.$on('OrganizzeFilter::init', (val = {}) => {
      makeSkeletonData(self, self.filterList, self.filterData)
      makeSkeletonData(self, self.filterList, self.filterDataBase)
      self.toggle = true
      // self.clearFilter()
      self.updateFilter(val.organizzeFilter)
    })

    Events.$on('OrganizzeFilter::buscar', () => self.enviar())
  },
  computed: {
    variables: () => variables,
    listFiltros () {
      let filtros = this.filterData
      if (!filtros.length) return []
      let keys = Object.keys(filtros)
      let itensSelect = compatc(map(keys, v => {
        if (filtros[v] && filtros[v].itens) {
          return {[v]: filtros[v].itens}
        }
        return false
      }))
      return itensSelect
    }
  },
   watch: {
    dates () {
      const self = this
      self.sortedDates = map(map(self.dates, date => moment(date).valueOf()).sort(), time => moment(time).format('YYYY-MM-DD'))
      self.datesLabel = map(self.sortedDates, date => moment(date).format('DD/MM/YYYY')).join(' ~ ')
    }
  },
  methods: {
    removeItem (name, key) {
      this.$set(this.filterData[name].items, key, {})
    },
    existeItens (v) {
      let itens = false
      v.forEach(it => { if (size(it) !== 0) itens = true })
      return itens
    },
    onSelectFilter (filterKey, itemIndex, item) {
      const self = this
      self.updateFilter(null, filterKey, itemIndex, item)
    },

    clearFilter () {
      const self = this
      for (const filterKey in self.filterData) {
        if (Object.hasOwnProperty.call(self.filterData, filterKey)) {
          self.filterData[filterKey].items.forEach((_, index) => {
            self.$set(self.filterData[filterKey].items, index, {})
          })
        }
      }

      self.dates = [moment().format('YYYY-MM-DD')]
      self._addlFilter = {}
      self._filter = {}
    },

    enviar () {
      const self = this

      delete self._addlFilter['Records:dtOrder_>=']
      delete self._addlFilter['Records:dtOrder_<=']

      self._addlFilter = {
        'Records:dtOrder_>=': self.sortedDates[0]  + ' 00:00:00',
        'Records:dtOrder_<=': (self.sortedDates[1] || self.sortedDates[0]) + ' 23:59:59',
        ...self._addlFilter
      }
      self.submit({ _addlFilter: self._addlFilter, _filter: self._filter, noInportOnly: this.noInportOnly})
      },

    updateFilter (organizzeFilter, filterKey, itemIndex, item) {
      const self = this
      self._addlFilter = {}
      self._filter = {}

      if ( isValidParam(organizzeFilter)) {  /** Verdadeiro se algum filtro de inicialização for passado. */
        organizzeFilter.forEach(val => { /** Atualiza o estatus atual do filtro. */
          const filterKey = val.filterKey
          const subKey = filterKey.substring(filterKey.indexOf(':') + 1, filterKey.indexOf('_'))
          const item = filter(self.filterList[filterKey].items, item => item[subKey] === val.item[subKey])[0]
          const itemIndex = self.filterList[val.filterKey].items.indexOf(item)
          self.$set(self.filterData[filterKey].items, itemIndex, item)
        })
      } else {
        if (isValidParam(filterKey) && isValidParam(itemIndex) && isValidParam(item)) { /** Verdadeiro se algum filtro for clicado pelo usúario. */
          if (self.filterData[filterKey].items[itemIndex] === item) { /** Verdadeiro se o usúario estiver desmarcando um filtro. */
            self.$set(self.filterData[filterKey].items, itemIndex, self.filterDataBase[filterKey].items[itemIndex])
          } else {
            if (!self.filterData[filterKey].isMultiple) self.filterData[filterKey].items.forEach((item, index) => self.$set(self.filterData[filterKey].items, index, self.filterDataBase[filterKey].items[itemIndex]))
            self.$set(self.filterData[filterKey].items, itemIndex, item)
          }
        }
      }

      for (const key in self.filterData) {  /** Atualiza o _addlFilter e o _filter */
        if (Object.hasOwnProperty.call(self.filterData, key)) {
          const filter = self.filterData[key]
          const subKey = key.substring(key.indexOf(':') + 1, key.indexOf('_'))
          const item = filter.items.filter(item => item[subKey] !== undefined)

          if (item.length !== 0) {
            if (!self.filterData[key].isMultiple) self._addlFilter[key] = item[0][subKey]
            else self._filter[key] = join(map(item, obj => obj[subKey]), ',')
          }
        }
      }
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .filterList {
    margin-right: 8px;
    color: #FFFFFF;
    background-color: $colorPrimary !important;

    &:hover {
      color: #000000;
    }
  }

  .filterArea {
    margin-top: 16px;
    background-color: $colorBlueBaby;
    padding: 8px 16px 8px 16px;
    align-items: center;
    border-radius: 16px;
  }

  .f {
    width: 100%;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    &-select {
      min-width: 50x;
      display: flex;
      flex-wrap: wrap;
      &-title {
        font-weight: bold;
        margin-right: 5px;
      }
      &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-right: 5px;
        border-radius: 5px;
        padding-left: 3px;
        font-size: 14px;
        font-weight: bold;
        color: #5cbc67;
        cursor: pointer;
        position: relative;
        &:hover{
          color: white;
          background: #5cbc67;
        }
      }
    }
  }
</style>